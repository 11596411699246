/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-heart': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9 5a3 3 0 11-6 0 3 3 0 016 0m-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4m13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276z"/>',
    },
});
